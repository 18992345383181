import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import { Text } from '../styled';

const Event = props => {
  const { data } = props;

  return (
    <Layout location={props.location}>
      <Helmet title={`${data.wordpressWpEvent.title} - Eddy Herrera`} />
      <div>
        <Container pt="2rem">
          <Row>
            <Column sm={8}>
              <h1>{data.wordpressWpEvent.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: data.wordpressWpEvent.content }} />
              {/* <Img sizes={data.wordpressWpEvent.featured_media.localFile.childImageSharp.sizes} /> */}
            </Column>
            <Column sm={4} pt="2rem">
              {data.wordpressWpEvent.acf.date && (
                <div>
                  <Text color="primary">
                    <b>Fecha</b>
                  </Text>
                  <div>{data.wordpressWpEvent.acf.date}</div>
                </div>
              )}

              {data.wordpressWpEvent.acf.time && (
                <div>
                  <Text color="primary" mt="1rem">
                    <b>Hora</b>
                  </Text>
                  <div>{data.wordpressWpEvent.acf.time}</div>
                </div>
              )}

              {/* <Text color="primary" mt="1rem">
                <b>Ubicación</b>
              </Text>
              <div>{data.wordpressWpEvent.acf.location.address}</div> */}
            </Column>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query EventQuery($slug: String!) {
    wordpressWpEvent(slug: { eq: $slug }) {
      title
      slug
      content
      # featured_media {
      #   alt_text
      #   localFile {
      #     childImageSharp {
      #       sizes(maxWidth: 1024) {
      #         ...GatsbyImageSharpSizes
      #       }
      #     }
      #   }
      # }
      acf {
        date
        time
      }
    }
  }
`;

export default Event;
